export default {
  methods: {
    transform(item) {
      return {
        ...item,
        created_at: this.dateFromISO8601(item.created_at),
        quantity: item.pcs * item.boxes
      };
    },
    tStatus: function (status) {
      return this.statuses.find((s) => s.value == status)?.text || '?';
    }
  },
  computed: {
    statuses() {
      return [
        { value: 'prepare', text: this.$t('warehouse.status.prepare') },
        { value: 'sent', text: this.$t('warehouse.status.sent') },
        { value: 'blocked', text: this.$t('warehouse.status.blocked') }
      ];
    }
  }
};
