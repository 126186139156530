<template>
  <v-card elevation="0" class="mx-2">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
        clearable
      />
    </v-card-title>

    <v-tabs v-model="tab" class="mb-3">
      <v-tab :disabled="loading">
        {{ $t('warehouse.header') }}
      </v-tab>
      <v-tab :disabled="loading">
        {{ $t('archived') }}
      </v-tab>
    </v-tabs>
    <v-data-table
      v-model="selected"
      show-select
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': [20, 40, 60, 80, 100],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :items-per-page="100"
      :search="search"
      :options.sync="options"
      :server-items-length="total"
      group-by="order"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      @click:row="expandRow"
      @toggle-select-all="handleSelectAll"
    >
      <template #top>
        <v-toolbar flat>
          <v-row align-self="end">
            <v-col class="d-flex justify-space-between align-center">
              <div>
                <div v-if="archiveVisible">
                  <v-btn
                    v-if="tab == 0"
                    color="primary"
                    dark
                    class="mb-2"
                    @click="updateArchivedFlag(true)"
                  >
                    {{ $t('archive') }}
                    <v-icon right dark> mdi-archive </v-icon>
                  </v-btn>

                  <v-btn
                    v-if="tab == 1"
                    color="primary"
                    dark
                    class="mb-2"
                    @click="updateArchivedFlag(false)"
                  >
                    {{ $t('restore') }}
                    <v-icon right dark> mdi-restore</v-icon>
                  </v-btn>
                </div>
              </div>
              <div>
                <v-btn
                  v-if="hasPermission('api.add_warehouseitem')"
                  color="primary"
                  dark
                  class="mb-2 mr-2"
                  @click="onNewItem"
                >
                  {{ $t('new') }}
                  <v-icon right dark> mdi-plus </v-icon>
                </v-btn>

                <v-btn color="primary" dark class="mb-2" @click="onExport">
                  {{ $t('export') }}
                  <v-icon right dark> mdi-file-excel </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template #[`item.created_at`]="{ item }">
        <span>{{ createdAt(item) }}</span>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <parcel-set :parcel-set="item" />
        </td>
      </template>
      <template #[`item.status`]="{ item }">
        <div class="pa-2">
          {{ tStatus(item.status) }}
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="hasPermission('api.change_warehouseitem')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasPermission('api.delete_warehouseitem')"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #[`group.header`]="{ items, isOpen, toggle }">
        <v-checkbox
          v-model="selected_orders[items[0].order]"
          @change="toggleGroupSelection(items, $event)"
        />
        <th colspan="2" class="secondary">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <span class="group white--text">{{ items[0].order }}</span>
        </th>
        <th colspan="1" class="text-right secondary">
          <span class="total white--text">{{
            totalBoxes(items[0].order)
          }}</span>
        </th>
        <th colspan="1" class="secondary"></th>
        <th colspan="1" class="text-right secondary">
          <span class="total white--text">{{
            totalQuantity(items[0].order)
          }}</span>
        </th>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import WarehouseService from '@/services/WarehouseService.js';
import warehouseMixin from './warehouseMixin';

export default {
  components: {
    parcelSet: () => import('./parcelSet.vue')
  },
  mixins: [warehouseMixin],
  props: {
    searchPhrase: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      searchTimeout: null,
      loading: false,
      search: '',

      items: [],
      selected: [],
      selected_orders: {},
      boxes: new Map(),
      quantity: new Map(),
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},

      expanded: [],

      tab: 0,

      options: {},
      total: 0
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('warehouse.item.id'),
          value: 'id',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.order'),
          value: 'order',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.boxes'),
          align: 'right',
          value: 'boxes',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.pcs'),
          align: 'right',
          value: 'pcs',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.quantity'),
          align: 'right',
          value: 'quantity',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.location'),
          value: 'location',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.operator'),
          value: 'operator',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.status'),
          value: 'status',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.created-at'),
          value: 'created_at',
          sortable: true
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    },
    archiveVisible() {
      return (
        this.hasPermission('api.change_warehouseitem') &&
        this.selected.length > 0
      );
    }
  },

  watch: {
    options: {
      async handler() {
        console.log('#options');
        await this.loadItems();
      },
      deep: true
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    },
    searchPhrase: {
      async handler() {
        console.log('#searchPhrase');
        await this.loadItems();
      }
    },
    async tab(tab, oldTab) {
      console.log('tab: ', oldTab, ' -> ', tab);
      this.selected = [];
      await this.loadItems();
    }
  },

  mounted() {
    this.$root.$on('warehouseitem_updated', this.onItemUpdated);
    this.$root.$on('warehouseitem_added', this.onItemAdded);
  },
  beforeDestroy() {
    this.$root.$off('warehouseitem_updated', this.onItemUpdated);
    this.$root.$off('warehouseitem_added', this.onItemAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;
      this.options.archived = this.tab == 1;

      let t0 = performance.now();
      WarehouseService.getItems(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search ? this.search : ''
      )
        .then((response) => {
          [this.items, this.total] = response;

          this.items = this.items.map(this.transform);

          this.boxes = this.items.reduce((acc, i) => {
            let sum = acc[i.order] || 0;
            acc[i.order] = sum + i.boxes;
            return acc;
          }, new Map());

          this.quantity = this.items.reduce((acc, i) => {
            let sum = acc[i.order] || 0;
            acc[i.order] = sum + i.quantity;
            return acc;
          }, new Map());

          // this.$root.$emit("warehouseitems:total", this.total);
        })
        .catch((err) => {
          this.showError(this, err);
        })
        .finally(() => {
          let t1 = performance.now();
          console.log('getItems took ' + (t1 - t0) + ' milliseconds.');
          this.loading = false;
        });
    },

    async updateArchivedFlag(value) {
      // console.log('archive(', value, '):', this.selected);
      for (let i of this.selected) {
        i.archived = value;
        try {
          let res = await WarehouseService.archive(i);
          let item = res.data;
          if (item.archived == value) {
            let index = this.items.findIndex((i) => i.id == item.id);
            this.items.splice(index, 1);
            this.total--;
          }
        } catch (err) {
          console.log('archive warehouse item error:', err);
          this.showError(this, err);
        }
      }
      this.selected = [];
      this.selected_orders = {};
    },

    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onItemAdded(item) {
      console.log('onItemAdded:', item);
      this.items.push(item);
    },

    onItemUpdated(item) {
      console.log('onItemUpdated:', item);
      let index = this.items.findIndex((c) => c.id == item.id);
      if (index != -1) {
        Object.assign(this.items[index], item);
      }
    },

    async deleteItem(item) {
      console.log('delete warehouse item:', item);
      const res = await this.$swal({
        html: this.$t('warehouse.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.items.indexOf(item);

      try {
        let result = await WarehouseService.delete(item);
        console.log('result: ', result);
        this.items.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onNewItem() {
      this.$emit('new');
    },

    createdAt(item) {
      return item.created_at.toLocaleString();
    },

    async onExport() {
      await WarehouseService.excel(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search ? this.search : ''
      );
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    totalBoxes(o) {
      return this.boxes[o] || 0;
    },

    totalQuantity(o) {
      return this.quantity[o] || 0;
    },

    toggleGroupSelection(items, isSelected) {
      if (isSelected) {
        items.forEach((item) => {
          if (!this.selected.includes(item)) {
            this.selected.push(item);
          }
        });
      } else {
        this.selected = this.selected.filter(
          (selectedItem) => !items.includes(selectedItem)
        );
      }
    },

    handleSelectAll(value) {
      if (value.value) {
        // All items are selected
        // this.selected_orders = this.items.map((item) => item.order);
        this.selected_orders = this.items.reduce((acc, item) => {
          acc[item.order] = true; // or whatever value you want to store
          return acc;
        }, {});
      } else {
        // All items are deselected
        this.selected_orders = {};
      }
    }
  }
};
</script>

<style lang="sass">
.group
  font-size: 15px
  font-weight: 500

.total
  font-size: 15px
  font-weight: 500
  text-align: right !important
  padding-right:0
</style>
