<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
    />
  </keep-alive>
</template>

<script>
import list from '@/views/dashboard/components/warehouse/warehouseList';
import form from '@/views/dashboard/components/warehouse/warehouseForm';
import PackageDefinitionService from '@/services/PackageDefinitionService.js';

export default {
  components: {},
  data: function () {
    return {
      loading: false,
      boxTypes: [],
      current: list,
      params: {}
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        const res = await PackageDefinitionService.getPackageDefinition();
        // console.log('package definitions:', res);
        this.boxTypes = res;
      } finally {
        this.loading = false;
      }
    },
    onNew() {
      this.current = form;
      this.params = {
        value: {
          id: '',
          created_at: new Date(),
          updated_at: new Date()
        }
      };
    },
    onEdit(item) {
      this.current = form;
      this.params = {
        value: item,
        boxTypes: this.boxTypes
      };
    },
    backToList() {
      this.current = list;
      this.params = {};
    }
  }
};
</script>

<style></style>
